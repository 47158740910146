<template>
    <div :class="{ 'blurItem' : blur }">
                <div class="md-layout md-alignment-top-right filters" v-if="matches.length > 0 || firstLoad==false">
                    <div class="md-layout-item md-size-15" v-if="facilities.length > 1">
                        <md-field>
                            <md-select placeholder="Listing" v-model="selectedFacility" name="selectedFacility" id="selectedFacility" @md-selected="onChange(selectedFacility)" mdClass="filtersPaddingTopFacility">
                                <md-option :value="0">View all</md-option>
                                <md-option v-for="(item) in facilities" :value="item.attributes.facilityId" :key="item.attributes.facilityId">{{ item.attributes.name }}</md-option>
                            </md-select>
                        </md-field>
                    </div>    
                    <div class="md-layout-item md-size-15">
                        <md-datepicker v-model="startDate" md-immediately>
                        </md-datepicker>
                    </div>
                    <div class="md-layout-item md-size-15">
                        <md-datepicker v-model="endDate" md-immediately>
                        </md-datepicker>
                    </div>
                </div>      
                <div class="md-layout">
                    <md-card v-if="matches.length>0"> 
                        <md-table class="md-table-mobile-padding">
                            <md-table-row class="white">
                                <md-table-head>Name</md-table-head>
                                <md-table-head v-if="!mobile">Source</md-table-head>
                                <md-table-head >Date</md-table-head>
                                <md-table-head v-if="!mobile">Time</md-table-head>
                                <md-table-head>Details</md-table-head>

                            </md-table-row>
                            <md-table-row v-for="(item) in showLeads" v-bind:key="item.id" class="grey">
                                <md-table-cell>{{ item | leadName }}</md-table-cell>
                                <md-table-cell v-if="!mobile">{{ item | leadSource }}</md-table-cell>
                                <md-table-cell>{{ item.date |date }}</md-table-cell>
                                <md-table-cell  v-if="!mobile">{{ item.date |time }}</md-table-cell>
                                <md-table-cell><a href="#" @click.prevent="openMoreInfo(item)">View</a></md-table-cell>
                            </md-table-row>
                        </md-table>


                        <div class="md-layout" v-if="matches.length > this.numShowMatches">

                            <div class="md-layout-item table-nav">
                                <md-button
                                      class="md-raised md-primary"
                                      :disabled="currentPage === 1"
                                      @click="previousPage"
                                >Previous</md-button>
                                <md-button
                                      class="md-raised md-primary"
                                      :disabled="nextDisabled"
                                      @click="nextPage"
                                >Next</md-button>
                            </div>

                        </div>
                      
                    </md-card>

                   <div class="container" v-show="matches.length == 0 && loading == false && firstLoad==false && user.status == 1 && subscriptions.length > 0">
                        <app-activityzero></app-activityzero>
                    </div>
                    <div class="container" v-show="matches.length == 0 && loading == false && firstLoad==true && user.status == 1 && subscriptions.length > 0">
                        <app-requestabedzero></app-requestabedzero>
                    </div>
                    <div class="container" v-show="loading == false && subscriptions.length === 0">
                        <app-requestabed></app-requestabed>
                    </div>
                    <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="loading && matches.length == 0"></md-progress-spinner>
                </div>

<!---
                <md-dialog :md-active.sync="showMoreInfoModal">
                    <md-dialog-title>Lead details</md-dialog-title>

                    <div class="dialog-exit" @click="showMoreInfoModal=false">
                      <md-icon >close</md-icon>
                    </div>


                    <ul v-if="showMoreInfoModal">
                        <li><strong>Date Received:</strong> {{ lead.date | date }} at {{ lead.date | time }}</li>
                        <li><strong>Name:</strong> {{ lead | leadName}}</li>
                        <li><strong>Phone:</strong> {{lead.phone}}</li>
                        <li><strong>Zipcode:</strong> {{fullLead.zip_details}}</li>
                        <li><strong>DOB:</strong> {{lead.dob}}</li>
                        <li><strong>How soon do you need help?</strong> {{fullLead.how_soon | formatHowSoon}}</li>
                        <li><strong>Who are you seeking treatment for?</strong> {{fullLead.match_for | formatWhoNeedsTreatment}}</li>
                        <li
                            v-if="fullLead.what_brings_you_to_treatment"
                        ><strong>What do you need help with?</strong> {{fullLead.what_brings_you_to_treatment | snakeToSpaced}}</li>
                        <li v-if="fullLead && fullLead.withdrawal && fullLead.withdrawal !== 'N/A'"><strong>Will you go through withdrawal if you stopped using?</strong> {{fullLead.withdrawal}}</li>
                        <li><strong>How will you pay for treatment?</strong> {{fullLead.how_will_you_pay_for_treatment |formatPay}}</li>
                        <li><strong>Comment: </strong>{{lead.comment ? lead.comment : 'None'}}</li>
                    </ul>
                </md-dialog>
-->
                <!--  DETAILS MODAL -->
            <md-dialog :md-active.sync="showMoreInfoModal" class="match">
            <img width="50" class="logoTop" src="/images/mini_logo.svg"/>
            <md-dialog-title class="title" v-if="fullLead">
                <template v-if="fullLead['first_name'] != 'Treatment'">
                    {{ fullLead['first_name'] | rawMatchName}} {{ fullLead['last_name'] | trimChar}}
                </template>
                <template v-else>
                    New connection
                </template>
                </md-dialog-title>
            <div class="dialog-exit">
                <img src="/images/icons.png">
                <a @click="showMoreInfoModal=false"><md-icon>close</md-icon></a>
            </div>
           
            <md-tabs
                md-alignment="centered"
                :class="[fullLead && fullLead['comment'] ? class3 : '', class2]"
                :md-active-tab="TabID"
                @md-changed="tabClick"
            >
           
                <md-tab id="tab-summary" md-label="Summary">
                    <span v-if="fullLead" class="description">
                    <template v-if="fullLead['first_name'] != 'Treatment'">
                        You connected with <span class="bold">{{ fullLead['first_name'] | rawMatchName }}</span>,<br>thanks to Rehab.com
                    </template>
                    <template v-else>
                        You have a new <span class="bold">patient connection</span>, thanks to Rehab.com
                    </template>
                    </span>
                    <span v-if="fullLead" class="days">{{ lead.date.date | matchDays }} ago</span>
                    <img src="/images/logo_large.png" height="100"/>
                    <span v-if="fullLead" class="case">
                        <template v-if="fullLead['first_name'] != 'Treatment'">
                            Take a minute to learn about {{ fullLead['first_name'] }}'s case.
                        </template>
                        <template v-else>
                            Take a minute to learn more.
                        </template>
                    </span>
                    <md-button
                        class="md-raised md-primary ctaCall case-details"
                        id="case-details"
                        @click="tabChange('tab-details')"
                    >Case Details</md-button>
                </md-tab>
            
                <md-tab id="tab-details" md-label="Details">
               
                    <span v-if="fullLead" class="description" >
                        <template v-if="fullLead['first_name'] != 'Treatment'">
                            Great job! <span class="bold">{{ fullLead['first_name'] | rawMatchName }}</span> connected with<br>your program.
                        </template>
                        <template v-else>
                           Great job! You have a new patient connection.
                        </template>
                    </span>
                
                    <img src="/images/logo_large.png" height="100"/>

                    <template v-if="fullLead">
                        <template v-if="fullLead['first_name'] != 'Treatment'">
                        <span v-if="fullLead"  class="case small"><span class="bold">{{ fullLead['first_name'] }} {{ fullLead['last_name'] }}:</span> {{ fullLead.phone }}</span>

                            <template v-if="fullLead['zip_city']!= null && !fullLead['dob']">
                            <span class="case">{{ fullLead['first_name'] }} from {{ fullLead['zip_city'] }}, {{ fullLead['state'] }}, connected with {{ fullLead.facilityName }} at {{ fullLead.date | time }} on {{ fullLead.date | dayOfWeek }}, {{ fullLead.date | month }}, {{ fullLead.date | day }}, {{ fullLead.date | year }}. {{ fullLead['first_name'] }} needs help {{fullLead.how_soon | formatHowSoon}} and intends to pay for care with {{fullLead.how_will_you_pay_for_treatment |formatPay}}.</span>
                            </template>

                            <template v-else-if="fullLead['zip_city'] == null && fullLead['dob']">
                            <span class="case">{{ fullLead['first_name'] }}, {{ fullLead['dob'] | years }} years old connected with {{ fullLead.facilityName }} at {{ fullLead.date | time }} on {{ fullLead.date | dayOfWeek }}, {{ fullLead.date | month }}, {{ fullLead.date | day }}, {{ fullLead.date | year }}. {{ fullLead['first_name'] }} needs help {{fullLead.how_soon | formatHowSoon}} and intends to pay for care with {{fullLead.how_will_you_pay_for_treatment |formatPay}}.</span>
                            </template>

                            <template v-else-if="fullLead['zip_city'] == null && !fullLead['dob']">
                            <span class="case">{{ fullLead['first_name'] }} connected with {{ fullLead.facilityName }} at {{ fullLead.date | time }} on {{ fullLead.date | dayOfWeek }}, {{ fullLead.date | month }}, {{ fullLead.date | day }}, {{ fullLead.date | year }}. {{ fullLead['first_name'] }} needs help {{fullLead.how_soon | formatHowSoon}} and intends to pay for care with {{fullLead.how_will_you_pay_for_treatment |formatPay}}.</span>
                            </template>

                            <template v-else>
                            <span class="case">{{ fullLead['first_name'] }}, {{ fullLead['dob'] | years }} years old from {{ fullLead['zip_city'] }}, {{ fullLead['state'] }}, connected with {{ fullLead.facilityName }} at {{ fullLead.date | time }} on {{ fullLead.date | dayOfWeek }}, {{ fullLead.date | month }}, {{ fullLead.date | day }}, {{ fullLead.date | year }}. {{ fullLead['first_name'] }} needs help {{fullLead.how_soon | formatHowSoon}} and intends to pay for care with {{fullLead.how_will_you_pay_for_treatment |formatPay}}.</span>
                            </template>

                            <span v-if="fullLead"  class="case">Rehab.com provided {{ fullLead['first_name'] }} with the direct number we have on file for your program: {{ fullLead.facilityPhone.content|phone }}</span>
                        </template>
                        <template v-else>
                            <span v-if="fullLead"  class="case small"><span class="bold">Contact:</span><a :href="'tel:'+fullLead.phone"> {{ fullLead.phone }}</a></span>
                            <span v-if="fullLead"  class="case">A new patient connection was made with {{ fullLead.facilityName }} at {{ fullLead.date | time }} on {{ fullLead.date | dayOfWeek }}, {{ fullLead.date | month }}, {{ fullLead.date | day }}, {{ fullLead.date | year }}. The name of patient who would like to connect with your prorgam is not known at this time. Please call them at the contact number they provided.</span>
                            <span v-if="fullLead"  class="case">Rehab.com provided the unnamed patient the direct number we have on file for your program: {{ fullLead.facilityPhone.content|phone }}</span>                    
                        </template>
                    </template>

                    <div class="hr"></div>              

                    <span v-if="fullLead"  class="case call">Schedule a call to connect cases like this directly to your CRM.</span>
                    <a target="_blank" id="schedule-call-now" :href="scheduleLink" class="url" @click="trackScheduleCall"><md-button class="md-raised md-primary ctaCall">Schedule Call Now</md-button></a>

                <!---<ul v-if="viewDetailsMatch">
                    <li><strong>Date Received:</strong> {{ viewDetailsMatch.date | date }} at {{ viewDetailsMatch.date | time }}</li>
                    <li><strong>Name:</strong> {{ viewDetailsMatch | rawMatchName}}</li>
                    <li><strong>Phone:</strong> {{viewDetailsMatch.phone}}</li>
                    <li><strong>Zipcode:</strong> {{viewDetailsMatch.zip_details}}</li>
                    <li><strong>DOB:</strong> {{viewDetailsMatch.dob}}</li>
                    <li><strong>How soon do you need help?</strong> {{viewDetailsMatch.how_soon | formatHowSoon}}</li>
                    <li><strong>Who are you seeking treatment for?</strong> {{viewDetailsMatch.match_for | formatWhoNeedsTreatment}}</li>
                    <li v-if="viewDetailsMatch.what_brings_you_to_treatment"
                    ><strong>What do you need help with?</strong> {{viewDetailsMatch.what_brings_you_to_treatment | snakeToSpaced}}</li>
                    <li v-if="viewDetailsMatch && viewDetailsMatch.withdrawal && viewDetailsMatch.withdrawal !== 'N/A'"><strong>Will you go through withdrawal if you stopped using?</strong> {{viewDetailsMatch.withdrawal}}</li>
                    <li><strong>How will you pay for treatment?</strong> {{viewDetailsMatch.how_will_you_pay_for_treatment |formatPay}}</li>
                    <li><strong>Comment: </strong>{{viewDetailsMatch.comment ? viewDetailsMatch.comment : 'None'}}</li>
                </ul>-->
                </md-tab>

                <md-tab id="tab-message" md-label="Message" v-if="fullLead && fullLead['comment']">
                    <span v-if="fullLead" class="description" >
                        <template v-if="fullLead['first_name'] != 'Treatment'">
                            Great job! <span class="bold">{{ fullLead['first_name'] | rawMatchName }}</span> connected with<br>your program.
                        </template>
                        <template v-else>
                           Great job! You have a new patient connection.
                        </template>
                    </span>
                
                    <img src="/images/logo_large.png" height="100"/>

                    <template v-if="fullLead">
                        <template v-if="fullLead['first_name'] != 'Treatment'">
                        <span v-if="fullLead"  class="case small"><span class="bold">{{ fullLead['first_name'] }} {{ fullLead['last_name'] }}:</span> {{ fullLead.phone }}</span>
                            <span v-if="fullLead && fullLead['comment']"  class="case">Message: {{fullLead['comment'] ? fullLead['comment'] : 'None'}}</span>
                        </template>
                        <template v-else>
                            <span v-if="fullLead"  class="case small"><span class="bold">Contact:</span><a :href="'tel:'+fullLead.phone"> {{ fullLead.phone }}</a></span>
                            <span v-if="fullLead && fullLead['comment']"  class="case">Message: {{fullLead['comment'] ? fullLead['comment'] : 'None'}}</span>
                        </template>
                    </template>
                    <span v-if="fullLead"  class="case call">Schedule a call to connect cases like this directly to your CRM.</span>
                    <a target="_blank" id="schedule-call-now" :href="scheduleLink" class="url" @click="trackScheduleCall"><md-button class="md-raised md-primary ctaCall">Schedule Call Now</md-button></a>

                </md-tab>



            </md-tabs>
            </md-dialog>




    </div>
</template>

<script lang="javascript">

    import config from '../config.js'
    import {mapActions, mapGetters} from 'vuex'
    import {
      differenceInYears,
      distanceInWords,
      endOfToday,
      format,
      isBefore,
      parse,
      startOfMonth,
      subMonths,
      addMinutes,
      subMinutes
    } from 'date-fns'

    export default{
        name: 'Leads',
        data(){
            return {
                selectedFacility: 0,
                facilities: {},
                facilitiesIDs:[],
                matches:[],
                config: config,
                startDate: startOfMonth(subMonths( new Date(), 48 )),
                endDate: endOfToday(),
                loading: true,
                firstLoad: true,
                user: [],
                blur: false,
                showMoreInfoModal: false,
                fullLead: null,
                mobile: false,
                TabID: 'tab-summary',
                lead: null,
                class2: "choice-tabs",
                class3: "choice-tabs listing-form",
                numShowMatches:15,
                currentPage: 1,

            }
        },
        watch: {
          showMoreInfoModal(value, oldValue){
            if(!value){
              this.TabID = 'tab-summary'
            }
          }
        },
        created(){
            //populate facilities drop down from the vuex store
            this.user = this.$store.getters.user
            this.facilities = this.$store.getters.facilities
            this.facilities.forEach((item) => {
                this.facilitiesIDs.push(item.attributes.facilityId)
            })
            this.$material.locale.dateFormat = "MM/dd/yyyy";
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
        },
        async mounted(){
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
            this.$watch('startDate', (value, oldValue) => {
                if(value.getTime() != oldValue.getTime()){
                    this.update()
                     this.firstLoad = false
                }
            })
            this.$watch('endDate', (value, oldValue) => {
                var valueA = new Date(value)
                valueA = valueA.getDate() + '/' + valueA.getMonth() + '/' + valueA.getFullYear()

                var valueB = new Date(oldValue)
                valueB = valueB.getDate() + '/' + valueB.getMonth() + '/' + valueB.getFullYear()

                if(valueA != valueB){
                   
                    this.update()
                    this.firstLoad = false
                }
            })
            
            if(this.user.status == 1){
                await this.update()
            }else{
                this.loading = false
            }
        },

        beforeRouteLeave(to, from, next){
            if(this.mobile && this.showMoreInfoModal){
                this.showMoreInfoModal = false;
                return next(false);
            }
            return next();
        },

        computed: {
            ...mapGetters({
                models: 'campaigns',
                defaultPaymentMethod: 'defaultPaymentMethod',
                checkCampaignsIsPremium: "checkCampaignsIsPremium",
                subscriptions:  'subscriptions',
                hubspotCompanyOwner: 'hubspotCompanyOwner',
            }),
            showLeads() {
               const start = this.numShowMatches * (this.currentPage - 1);
               const end = start + this.numShowMatches;
               return this.matches.slice(start, end)
            },
            nextDisabled() {
              if(this.currentPage === Math.ceil(this.matches.length / this.numShowMatches)){
                return true;
              }
              return false;
            },
            scheduleLink() {
              // check if company owner is Erin
              //if(this.hubspotCompanyOwner && this.hubspotCompanyOwner.firstName && this.hubspotCompanyOwner.firstName === 'Erin'){
             //   return "https://meetings.hubspot.com/erin-bigelow/schedule-your-introductory-call-with-erin-bigelow"
            //  }
              // if not Erin, return Kyle's link
              return "https://meetings.hubspot.com/kyle208/schedule-your-introductory-call-with-kyle-rice"
            }
        },
        filters: {
            years(date){
                const newDate = parse(date);
                return differenceInYears(new Date(), newDate)
            },
            phone(phoneNumberString){
                var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
                var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
                if (match) {
                    var intlCode = (match[1] ? '+1 ' : '')
                    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
                }
                return null
            },
            date(date){
                const dateString = date.date;
                const localDate = new Date(`${dateString} UTC`);
                return format(localDate, 'MM/DD/YYYY')
            },
            time(date){
                const dateString = date.date;
                const localDate = new Date(`${dateString} UTC`);
                return format(localDate, 'hh:mm a')
            },
            day(date){
                const dateString = date.date;
                const localDate = new Date(`${dateString} UTC`);
                return format(localDate, 'D')
            },
            year(date){
                const dateString = date.date;
                const localDate = new Date(`${dateString} UTC`);
                return format(localDate, 'YYYY')
            },
            month(date){
                const dateString = date.date;
                const localDate = new Date(`${dateString} UTC`);
                return format(localDate, 'MMMM')
            },
            dayOfWeek(date){
               const dateString = date.date;
                const localDate = new Date(`${dateString} UTC`);
                return format(localDate, 'dddd')
            },
            rawMatchName(match) {
                //console.log(match)
                // console.log('THE MATCH', JSON.parse(JSON.stringify(match)));
                if (match.includes('Treatment') || match.includes('Seeker')) {
                    return 'Undisclosed'
                } else {
                    return match
                }
            },
             trimChar(match) {
                //console.log(match)
                // console.log('THE MATCH', JSON.parse(JSON.stringify(match)));
                if (match.includes('Treatment') || match.includes('Seeker')) {
                    return 'Undisclosed'
                } else {
                    return `${match.charAt(0)}.`
                }
            },
            
            matchDays(date){
                let parsedDate = parse(date);
                parsedDate = subMinutes(parsedDate, parsedDate.getTimezoneOffset())
                const now = new Date();
                return distanceInWords(parsedDate, now);
            },
            formatHowSoon(time){
                if(time === 'a_few_days'){
                    return 'in a few days'
                }else if(time === 'right_now'){
                    return "now"
                }else if(time === 'within_a_week'){
                    return "within a week"
                }else if(time === 'within_a_month'){
                    return "within a month"
                }
                return time;
            },
            formatWhoNeedsTreatment(who){
                if(who === 'myself'){
                    return 'Myself'
                }else {
                    return 'A loved one'
                }
            },
            formatPay(paymentType){
                if(paymentType === 'self_pay'){
                    return 'Self pay'
                }else {
                    return paymentType.replace(/^\w/, c => c.toLowerCase());
                }
            },
            snakeToSpaced(val){
                val = val.replace('_', ' ')
                return val.charAt(0).toUpperCase() + val.slice(1);
            },
            leadName(lead) {
              if(lead.firstName.includes('Treatment') || lead.lastName.includes('Seeker')){
                return 'Undisclosed'
              }else {
                return `${lead.firstName} ${lead.lastName.charAt(0)}.`
              }
            },
            leadSource(lead) {
              if(lead.priorityType && lead.priorityType === 'Match Featured Listing Lead' || lead.subtype == 'Display-ad'){
                return 'Ad'
              }
              return 'Listing'
            }
        },
        methods: {
            ...mapActions([
                'fetchSingleMatch',
                'fetchSingleLead',
                'fetchPremiumLeads'
            ]),
            async update(){
                 if(this.user.status == 1){
                    await this.getFacilityMatches()
                }else{
                    this.loading = false
                }
                
            },
            getDate(date){


                let endDate = this.endDate.getFullYear() + '-' + (((this.endDate.getMonth() + 1) < 10) ? '0' : '') + (this.endDate.getMonth() + 1) + '-' + ((this.endDate.getDate() < 10) ? '0' : '') + this.endDate.getDate();
                let startDate = this.startDate.getFullYear() + '-' + (((this.startDate.getMonth() + 1) < 10) ? '0' : '') + (this.startDate.getMonth() + 1) + '-' + ((this.startDate.getDate() < 10) ? '0' : '') + this.startDate.getDate();

                return date == 'startDate' ? startDate : endDate
            },
            onChange(event){
                if(event === 0){
                     this.facilities.forEach((item) => {
                        this.facilitiesIDs.push(item.attributes.facilityId)
                    })
                }else{
                   this.facilitiesIDs = [event]
                }
                this.$children.find(child => { return child.$options.name === "ActivityZero"; }).updateFacility();
                this.getFacilityMatches()
                this.firstLoad = false
            },
            async getFacilityMatches(){

                const startDate = format(this.startDate, 'YYYY-MM-DD');
                const endDate = this.formatApiEndDate(this.endDate);

                const result = await this.fetchPremiumLeads({
                  facilityIds: this.facilitiesIDs,
                  startDate,
                  endDate
                });

                // console.log('THE LEADS', result);

                if(result.data && result.data.length){
                    this.matches = result.data;
                    this.total_matches = result.total;
                }else {
                  this.loading = false;
                  this.matches = []
                }
            },
            async openMoreInfo(lead){

                this.lead = lead

                if(lead.serviceLoginDataCaptureId){
                    this.fullLead = await this.fetchSingleMatch(lead.serviceLoginDataCaptureId);
                }else{
                    this.fullLead = await this.fetchSingleLead(lead.id);
                }
                //console.log('LEAD', this.fullLead);
                this.showMoreInfoModal = true;
            },

            tabChange(tab){
                this.TabID = tab;

                const gtmEvent = {
                    event: 'case-details', // Event type [default = 'interaction'] (Optional)
                    category: "Button",
                    action: "click",
                    label: "Case Details",
                    value: 1,
                    noninteraction: false, // Optional
                }
                this.$gtm.trackEvent(gtmEvent);
            },

            tabClick(event){
              this.TabID = event;
            },

            trackScheduleCall(){
                const gtmEvent = {
                    event: 'schedule-call-now', // Event type [default = 'interaction'] (Optional)
                    category: "Button",
                    action: "click",
                    label: "Schedule Call Now",
                    value: 1,
                    noninteraction: false, // Optional
                }
                this.$gtm.trackEvent(gtmEvent);
            },

          previousPage() {
              if(this.currentPage > 1){
                this.currentPage--
              }
          },

          nextPage() {
              this.currentPage++;
          }

        }
    }

</script>

<style lang="scss" scoped>

    .phone{
    white-space: nowrap;
    }


    .match .ctaCall{
        background-color: #1860e2;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 5px;
    }
    .blurItem{
        filter: blur(2px);
    }

    .match .logoTop{
         padding: 5px;
         width: 64px;
         padding-left: 10px;
         padding-top:7px;
    }

    .choice-tabs{
        margin-top: 22px;
        text-align: center;
        img{
            display: block;
            position: relative;
            margin-top: 0;
           
        }
        .case{
            font-size: 14px;
            margin-top: 20px;
        }

        #tab-details, #tab-message{
            .description{
            margin-top: 15px;
            padding-bottom: 0px;
            }
            img{
            height: 146px;
            }
            .case{
            margin-top: 0;
            color: #646464;
            padding-bottom: 4px;
            padding-top: 2px;
            line-height: 22px;
            .bold{
                font-weight: 500;
                display: inline;
                padding: 0;
                margin: 0;
            }
            }
            .case.small{
            font-size: 13px;
            margin-bottom: 4px;
            }
            .case.call{
            margin-top: 24px;
            font-weight: 500;
            font-size: 13px;
            margin-bottom: 8px;
            }
        }
        .hr{
            width: 200px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            border-bottom: 1px solid #ededed;
            margin-top: 12px;
        }
    }

    .md-button-content{
        font-family: 'Roboto,Noto Sans,-apple-system,BlinkMacSystemFont,sans-serif';
        color: #646464;
        font-size: 10px!important;
    }

    span {
      width: 100%;
      height: 100%;
      padding: 8px;
      display: block;
      background: md-get-palette-color(blue, 200);
    }
    .comment{
        max-width: 460px;
    }
    .notice{
        margin-top: 80px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 50%;
        .title{
            font-size: 22px;
            font-weight: 500;
        }
        .intro{
            font-size: 16px;
            font-weight: 300;
           
        }
        span{
            height: unset;
        }
    }
    .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .spinner{
        margin-left: auto;
        margin-right: auto;
        margin-top: 18%;
        position: absolute;
        left:50%;
        margin-left: -40px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }
    .white{
        background-color: #fff;
    }
    .center{
        text-align: center;
    }
    .align-right {
        text-align: right;
    }
    .md-field.md-theme-default:after{
        background-color: #DBDBDB;
    }
    .container{
        width: 100%;
    }
    .md-dialog-container { 
    img{
        margin-left: auto;
        margin-right: auto;
        margin-top: 4px;
    }
    .md-title{
        font-size: 14px;
        margin-top: 0;
        padding-top: 0;
        text-align: center;
        margin: 0;
        line-height: 18px;
    } 
    .days{
        font-size: 12px;
        color: #646464;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0;
        padding-bottom: 0;
        padding-top: 0px;
    }
    .description{
        font-size: 26px;
        font-weight: 300;
        line-height: 30px;
        text-align: center;
        margin-top: 35px;
        .bold{
        font-weight: 400;
        display: inline;
        padding: 0;
        margin: 0;
        }
        width: 100%;
    }
     span{
            height: unset;
        }
    }

    .md-dialog{
        border-radius: 10px;
        width: 490px;
        padding: 0 25px 2px;
        /*padding-bottom: 2px;*/
        height: 759px;
        min-height: 759px;
        overflow: scroll;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;

        .md-dialog-title{
            padding-bottom: 0px;
            margin-bottom: 0px;
            text-align: center;
            padding-top: 0px;

        }
        .md-list-item-text{
            font-size: 14px;
        }
        .dialogList{
            padding: 10px;
            padding-bottom: 0px;
        }
        .md-content{
            padding-left:0px;
            text-align: center;
        }
        .md-button{
            margin-top: 7px;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
        }
        ul {
            list-style: none;
            padding-inline-start: 0;
        }
      .dialog-exit {
        position: absolute;
        right: 15px;
        top: 22px;
        cursor: pointer;
        img{
            margin-right: 8px;
        }
      }
    }

    .dialog-exit a:hover{
        text-decoration: none;
        color: #dbdbdb;
    }

    .dialog-exit .md-icon.md-theme-default.md-icon-font{
        color: #dbdbdb;
        margin-top: 5px;
    }

    .table-nav {
      text-align: right;
      margin-top: 25px;
      margin-bottom: 25px;
      padding-right: 22px
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
      .md-dialog-container .md-tab {
        padding: 0;
      }
       .match{
         overflow-x: hidden;
         overflow-y: scroll;
         border-radius: 0;
       }
    }

     @media only screen and (max-width: 1500px) {
            .md-dialog.match{
                top:417px;
            }
        }

         @media only screen and (min-width: 1500px) {
            .md-dialog.match{
                top:417px;
            }
        }


        a{
            color: #0861E7!important;
        }
</style>